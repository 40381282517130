<template>
  <div class="user-profile-content">
    <form v-if="userRef">
      <EditActions :edit-mode="{ canEdit, isEditing, canSave, saveText, canCancel }" edit-text="Edit user profile"
        @edit="edit" @save="save" @cancel="cancel" />
      <div v-if="isEditing" class="row">
        <div class="col location">
          <label class="form-label">Location</label>
          <input type="text" 
            class="form-control"
            v-model="editMetadata.location"
            :readonly="!canEditValues"
            placeholder="city, state, country"/>
          <div class="help-text">
            Suggested: {{ suggestedLocationRef }}
            <button type="button" v-if="suggestedLocationRef" class="btn btn-inline btn-link" @click="useSuggestedLocation">
              Use this
            </button>
          </div>
        </div>
      </div>
      <div v-if="isEditing" class="row">
        <div class="col col-sm-3 social-accounts">
          <label class="form-label">Social accounts</label>
          <ul class="list-unstyled">
            <li v-for="p in socialAccountProviders" :key="p.id">
              <img :src="p.icon" :alt="p.name"/>
              <span>@</span>
              <input type="text" 
                class="form-control"
                :value="editMetadata.getSocialUsernameFor(p.id)"
                @input="e => onSocialAccountChange(e, p.id)"
                placeholder="username"/>
            </li>
          </ul>
        </div>
      </div>
      <div v-if="isEditing || userRef.metadata?.genealogyStartYear" class="row input-row">
        <div class="col col-sm-3">
          <label for="startYearInput" class="form-label">Genealogist since</label>
          <input type="text" id="startYearInput" 
            class="form-control" 
            v-if="isEditing" 
            :readonly="!canEditValues" 
            v-model="editMetadata.genealogyStartYear" 
            pattern="\d*" 
            maxlength="4"
            inputmode="numeric"
            placeholder="year"/>
          <div class="big-number" v-show="!isEditing">
            {{userRef.metadata?.genealogyStartYear}}
            <span class="secondary-text">({{ startYearAgoRef }} yrs)</span>
          </div>
        </div>
      </div>
      <div v-if="isEditing || userRef.metadata?.researchTools?.length" class="row">
        <div class="col research-tools">
          <label class="form-label">Research tools</label>
          <ul class="list-unstyled">
            <li v-for="tid in researchToolsRef" :key="tid">
              <ResearchToolItem :toolId="tid" />
            </li>
            <li class="add-tool">
              <button type="button" v-if="isEditing" class="btn btn-outline-primary" data-bs-toggle="modal" data-bs-target="#researchToolsModal">
                +
              </button>
            </li>
          </ul>
        </div>
      </div>
      <!-- <div>Research data</div>
      <div>Research activity</div>
      <div>Family surnames</div> -->
    </form>
    <ResearchToolsModal id="researchToolsModal" v-model="researchToolsRef"></ResearchToolsModal>
  </div>
</template>

<style lang="scss" scoped>
.user-profile-content form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.location {
  input {
    max-width: 400px;
  }
  .help-text, button {
    font-size: 0.75rem;
  }

  button {
    margin-left: 0.5rem;
  }
}

.social-accounts {
  ul {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  li {
    display: flex;
    align-items: center;
  }

  img {
    margin-right: 0.5rem;
    width: 1.5rem;
  }

  span {
    margin-right: 0.25rem;
    color: #aaa;
  }
}

.research-tools {
  ul {
    margin: 0.5rem 0 0;
    display: flex;
    flex-wrap: wrap;
  }

  .add-tool {
    width: 90px;
    height: 90px;
    padding-top: 8px; // same as tool item
    display: flex;
    justify-content: center;

    button {
      width: 40px;
      height: 40px;
      border-color: transparent;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 3.3rem;
      font-weight: 300;
      //color: #333;

      &:hover {
        background-color: #a1cbff;
        color: var(--bs-primary);
      }
    }
  }
}

.big-number {
  font-size: 1.75rem;
  font-weight: 300;

  .secondary-text {
    font-size: 0.875rem;
    color: #888;
  }
}
</style>

<script setup lang="ts">
import { computed, reactive, ref, watch } from 'vue'
import { useUserStore } from '@/gp/UserStore'
import { useIdentityApi } from '@/auth/IdentityApi'
import { ItemPermissions, socialAccountProviders, UserMetadata } from '@/gp/GroupAdminModel'
import { researchToolInfos } from '@/rd/ResearchTools'
import { propertyChanged, useEditMode } from '@/util/EditMode'
import { TokenManager } from '@/auth/TokenManager'
import { LoadMode } from '@/util/AsyncData'
import { PatchChange, PatchChangeValue } from '@/util/Api'
import { DateTime } from 'luxon'
import EditActions from '@/util/EditActions.vue'
import ResearchToolItem from './ResearchToolItem.vue'
import ResearchToolsModal from './ResearchToolsModal.vue'

const props = defineProps<{
  userId: string
}>()

defineExpose({
  canEdit: () => canEdit.value,
  edit: () => edit(),
})

const suggestedLocationRef = ref<string>()

const userStore = useUserStore()
const identityApi = useIdentityApi()

const userIdRef = computed(() => props.userId ?? TokenManager.userId!)
const userRef = computed(() => userStore.getAsyncUser(userIdRef.value)?.data)
const metadataRef = computed(() => userRef.value?.metadata)

const startYearAgoRef = computed(() => DateTime.now().year - (metadataRef.value?.genealogyStartYear ?? 0))

const researchToolsRef = computed({
  get: () => {
    const metadata = isEditing.value ? editMetadata : metadataRef.value
    const toolIds = metadata?.researchTools ?? []
    return researchToolInfos.filter(t => toolIds.includes(t.id)).map(t => t.id) // in display order
  },
  set: value => {
    editMetadata.researchTools = value
  }
})

const editMetadata = reactive(new UserMetadata())
const editProperties = [
  'location',
  'genealogyStartYear',
  'researchTools', 
  'showResearchActivity',
  'showFamilySurnames',
  'socialAccounts',
] as (keyof UserMetadata)[]

const allowEdit = computed(() => {
  const perms = userStore.getAsyncPermissions(userIdRef.value, LoadMode.EnsureLoaded)?.data ?? ItemPermissions.None
  return (perms & ItemPermissions.Modify) > 0
})
const allowSave = computed(() => true)

const { canEdit, canEditValues, isEditing, edit, canSave, saveText, saveChanges, canCancel, cancel } = 
  useEditMode(metadataRef, editMetadata, editProperties, allowEdit, allowSave)

watch([userRef, allowEdit], async () => {
  if (userRef.value && allowEdit.value) {
    const ident = await identityApi.getIdentityAsync(userRef.value.subject!)
    suggestedLocationRef.value = ident?.metadata?.clientLocationName
  }
}, { immediate: true })

function useSuggestedLocation() {
  editMetadata.location = suggestedLocationRef.value
}

function onSocialAccountChange(e: Event, providerId: string) {
  const input = e.target as HTMLInputElement
  const value = input.value.trim()
  const accountIndex = editMetadata.socialAccounts?.findIndex(a => a.startsWith(providerId + ':')) ?? -1
  if (value == '') {
    if (accountIndex >= 0) {
      editMetadata.socialAccounts?.splice(accountIndex, 1) // remove account
    }
  }
  else {
    if (accountIndex >= 0) {
      editMetadata.socialAccounts![accountIndex] = providerId + ':' + value // update account
    }
    else {
      editMetadata.socialAccounts ??= []
      editMetadata.socialAccounts.push(providerId + ':' + value) // add account
    }
  }
}

function save() {
  saveChanges(async () => {
    const changes: PatchChange[] = []
    if (!userRef.value)
      return

    const metadata = userRef.value.metadata ?? new UserMetadata()
    
    for (const key of editProperties) {
      if (propertyChanged(metadata[key], editMetadata[key])) {
        const value = editMetadata[key] as PatchChangeValue
        changes.push(new PatchChange(`/metadata/${key}`, value == '' ? undefined : value))
      }
    }

    if (changes.length) {
      await userStore.patchAsync(userRef.value.id!, changes)
    }
  })
}

</script>
