<template>
  <div class="signup-grant-alert">
    <div v-if="signupGrantActiveRef" class="alert alert-success alert-sm block-source">
      <img v-if="sourceIconRef" :src="sourceIconRef"/>
      <div>
        Thanks for visiting us from <b>{{ signupGrantRef?.sourceName }}</b>.
        You arrived in time to join a preview group!
      </div>
    </div>
    <div v-else-if="signupGrantRef" class="alert alert-warning alert-sm block-source">
      <img v-if="sourceIconRef" :src="sourceIconRef"/>
      <div>
        Thanks for visiting us from <b>{{ signupGrantRef?.sourceName }}</b>!
        You didn't make the cutoff for this preview group, but you can save your place in line for the next one!
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.alert.block-source {
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;

  img {
    height: 45px;
  }
}
</style>

<script lang="ts" setup>
import { computed } from 'vue'
import { useSignInStore } from '@webapp/auth/SignInStore'
import { Environment } from '@webapp/util/Environment'
import { useIdentityBlockGrantStore } from './IdentityBlockGrantStore'
import { LoadMode } from '@webapp/util/AsyncData'

const signInStore = useSignInStore()
const blockGrantStore = useIdentityBlockGrantStore()

const signupGrantRef = computed(() => blockGrantStore.getBlockGrant(signInStore.couponCode))
const signupGrantActiveRef = computed(() => signupGrantRef.value && signupGrantRef.value.used < signupGrantRef.value.allocated)
const sourceIconRef = computed(() => signupGrantRef.value?.sourceIcon ? `${Environment.get('INTEGRATION_CONTENT_URL')}/${signupGrantRef.value.sourceIcon}` : undefined)

</script>
