import { Api, ApiError, PatchChange } from '@webapp/util/Api'
import { AccessTokenResponse } from './AccessTokenResponse'
import { DateTime, Duration } from 'luxon'
import { DateTimeUtil, DurationUtil } from '@webapp/util/LuxonUtil'
import { assignExisting, isDefined } from '@webapp/util/TypeScriptUtil'
import { useTokenManager } from '@webapp/auth/TokenManager'
import { Environment } from '@webapp/util/Environment'

const idUrl = Environment.get('IDENTITY_URL')

export function useIdentityApi() {
    const tokenManager = useTokenManager()
    const idApi = new Api(idUrl, () => tokenManager.getIdentityTokenAsync())
    const anonApi = new Api(idUrl, () => '')

    async function getIdentityAsync(subject: string) {
        const p = await idApi.getPlainAsync(`identities/${subject}`, { expand: "addresses" })
        return plainToUserIdentity(p)
    }

    async function patchAsync(subject: string, patch: PatchChange[]) {
        const p = await idApi.patchPlainAsync(`identities/${subject}`, patch)
        return plainToUserIdentity(p)
    }

    async function signUpAsync(request: SignUpRequest) {
        const p = await anonApi.postPlainAsync("identities", request)
        return plainToUserIdentity(p)
    }
    
    async function sendSignInCodeAsync(address: string) {
        const body = { address }
        await anonApi.postPlainAsync("identities/$securelookup/sendcode", body)
    }

    async function generateSignInCodeAsync(subject: string, lifetime?: Duration) {
        const p = await idApi.postPlainAsync(`identities/${subject}/generatecode`, undefined, { lifetime: DurationUtil.toReadable(lifetime) })
        return plainToSignInCode(p)
    }

    async function signInAsync(username: string, password: string) {
        const request = {
            grant_type: "password",
            requested_token_type: "access_token",
            username,
            password,
            audience: Environment.get('IDENTITY_TOKEN_AUDIENCE')
        }
        return await anonApi.postPlainAsync('oauth2/token', request, {}, true) as AccessTokenResponse;
    }

    async function tryGetBlockGrantAsync(blockId: string) {
        try {
            const p = await anonApi.getPlainAsync(`blockgrants/${blockId}`)
            p.used = p.used ?? 0
            return p as BlockGrant
        }
        catch (ex) {
            if (ex instanceof ApiError && ex.statusCode == 404) {
                return
            }
            throw ex
        }
    }

    return {
        getIdentityAsync,
        patchAsync,
        signUpAsync,
        sendSignInCodeAsync,
        generateSignInCodeAsync,
        signInAsync,
        tryGetBlockGrantAsync,
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- apis return plain objects
function plainToUserIdentity(p: any) {
    const ident = assignExisting(new UserIdentity(), p)
    ident.createdDate = DateTimeUtil.fromAPI(p.createdDate)
    ident.metadata = p.metadata ? assignExisting(new UserIdentityMetadata(), p.metadata) : undefined
    ident.addresses = (p.addresses as []).map(a => plainToUserIdentityAddress(a))
    ident.isTestIdentity = p.isTestIdentity ?? false
    return ident
}

function plainToUserIdentityAddress(p: any) {
    const addr = assignExisting(new UserIdentityAddress(), p)
    addr.createdDate = DateTimeUtil.fromAPI(p.createdDate)
    addr.verifiedDate = DateTimeUtil.fromAPI(p.verifiedDate)
    addr.inactiveDate = DateTimeUtil.fromAPI(p.inactiveDate)
    return addr
}

function plainToSignInCode(p: any) {
    p.createdDate = DateTimeUtil.fromAPI(p.createdDate)
    p.expirationDate = DateTimeUtil.fromAPI(p.expirationDate)
    return p as SignInCode
}

export interface SignUpRequest {
    givenName?: string
    familyName?: string
    address: string
    captchaCode: string
}

export class UserIdentity {
    subject?: string
    givenName?: string
    familyName?: string
    isTestIdentity = false
    disabled = false
    createdDate?: DateTime
    metadata?: UserIdentityMetadata
    addresses: UserIdentityAddress[] = []
}

export class UserIdentityMetadata {
    appSupportNotify: string[] = []
    clientLatitude?: number
    clientLongitude?: number
    clientTimeZone?: string
    clientCity?: string
    clientRegion?: string
    clientCountry?: string

    get clientLocationName() {
        return [this.clientCity, this.clientRegion, this.clientCountry].filter(isDefined).join(", ")
    }
}

export class UserIdentityAddress {
    subject?: string
    address?: string
    createdDate?: DateTime
    verifiedDate?: DateTime
    inactiveDate?: DateTime
}

export interface SignInCode {
    subject: string
    createdDate: DateTime
    expirationDate: DateTime
    code: string
}

export interface BlockGrant {
    blockId: string
    sourceName: string
    sourceUrl: string
    sourceIcon?: string
    allocated: number
    used: number
}
