import { reactive } from "vue"
import { defineStore } from "pinia";
import { BlockGrant, useIdentityApi } from "@webapp/auth/IdentityApi"
import { AsyncData, LoadMode } from "@webapp/util/AsyncData"
import { DateTime } from "luxon"
import { AsyncDataStore } from "@/util/AsyncDataStore"

export const useIdentityBlockGrantStore = defineStore("identityBlockGrantStore", () => {
    const asyncBlockGrants = reactive(new Map<string, AsyncData<BlockGrant>>())

    const identityApi = useIdentityApi()

    function getAsyncBlockGrant(id: string | undefined, loadMode = LoadMode.TrackOnly) {
        const loadAsync = async (ids: string[], requestTime: DateTime) => {
            const b = await identityApi.tryGetBlockGrantAsync(ids[0])
            console.log(`Loaded block grant ${id}`)
            return new Map([[ids[0], b]])
        }
        return AsyncDataStore.getAsyncItemsByIds(asyncBlockGrants, [id], "BlockGrant", loadMode, loadAsync).at(0)
    }

    function getBlockGrant(id: string | undefined) {
        return getAsyncBlockGrant(id, LoadMode.EnsureLoaded)?.data
    }

    return {
        asyncBlockGrants,

        getAsyncBlockGrant,
        getBlockGrant,
    }
})
