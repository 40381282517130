/**
 * Type guard function that returns a value indicating whether the specified object is not undefined.
 */
export function isDefined<T>(obj: T | undefined): obj is T {
    return obj !== undefined
}

/**
 * Type guard function that returns a value indicating whether the specified object is truthy (not undefined/null/empty/0).
 */
export function isTruthy<T>(obj: T | undefined): obj is T {
    return !!obj
}

/**
 * Assigns values to the existing properties on the target using the values of properties of the same name on the source. 
 * The types of the values assigned may not match the types of the properties on the target (caller should know these 
 * and fix them after calling).
 */
export function assignExisting<T>(target: T, source: any) { // eslint-disable-line @typescript-eslint/no-explicit-any
    const plainTarget = target as any // eslint-disable-line @typescript-eslint/no-explicit-any
    for (const key in target) {
        plainTarget[key] = source[key]
    }
    return target
}

export type PromiseOrValue<T> = T | Promise<T>